import React from "react"
import { Route } from "react-router-dom"
import "./App.css"
import routes from "./Routes"
import Navbar from "./components/NavBar/Navbar"
import Footer from "./components/Footer/Footer"

function App() {
  return (
    <div className="App">
      <Navbar />
      {routes.map((route) => (
        <Route
          exact
          key={route.path}
          path={route.path}
          component={route.component}
        />
      ))}
      <Footer />
    </div>
  )
}

export default App
