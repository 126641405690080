import React from "react"
import "./B.css"
// import Background from "https://images.unsplash.com/photo-1675277347872-e1d3cf48c96f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"

var sectionStyle = {
  top: "0",
  bottom: "0",
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundImage: `url('https://images.unsplash.com/photo-1675277347872-e1d3cf48c96f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80')`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}

function B() {
  return (
    <div className="home-contianer">
      <div className="mixcloud-home">
        <span style={{fontSize: "100px"}}>🫠</span>
      </div>
      {/* <div className="container-book-now">
        <a
          className="cta"
          href="mailto:pkwdigital@gmail.com?subject=Booking inquiry"
        >
          <button className="book-now">BOOK NOW</button>
        </a>
      </div> */}
      <section rel="preload" style={sectionStyle}>
        {" "}
      </section>
    </div>
  )
}

export default B
