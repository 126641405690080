import React from "react"
import "./Navbar.css"
import { Link } from "react-router-dom"

const Navbar = () => {
  return (
    <header id="navbar-header">
      <h2 className="logo" alt="logo">
        <Link to="/">Encrypted Kenyan</Link>
      </h2>
      <nav>
        <ul className="nav__links">
          {/* <li>
            <Link to="/twitch">Twitch</Link>
          </li> */}
          {/* <li>
            <Link to="/B">B</Link>
          </li> */}
        </ul>
      </nav>
    </header>
  )
}

export default Navbar