import React from "react"
import "./Home.css"
// import Background from "./ekshadow.jpg"

// var sectionStyle = {
//   top: "0",
//   bottom: "0",
//   position: "absolute",
//   width: "100%",
//   height: "100%",
//   backgroundImage: `url('https://images.unsplash.com/photo-1675278182233-52a1496c87f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80')`,
//   backgroundPosition: "center",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
// }

function Home() {
  return (
    <div className="home-contianer">
      <div className="mixcloud-home">
        <iframe
        title="mix-cloud-player"
          width="100%"
          height="120"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fencryptedkenyan%2F201918%2F"
          frameborder="0"
        ></iframe>
      </div>
      <div className="container-book-now">
        <a
          className="cta"
          href="mailto:pkwdigital@gmail.com?subject=Booking inquiry"
        >
          <button className="book-now">BOOK NOW</button>
        </a>
      </div>
      {/* <section rel="preload" style={sectionStyle}>
        {" "}
      </section> */}
    </div>
  )
}

export default Home
