import Home from "./components/Home/Home"
import Twitch from "./components/TwitchPlayer/Twitch"
import B from "./components/B/B"

const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/twitch",
    component: Twitch
  },
  {
    path: "/B",
    component: B
  }
]

export default routes